<!--下级代理商用户管理-->
<template>
    <div class="tools">
        <el-button :disabled="isLoading" @click="this.$router.go(0);">{{lang['html.el-button.refresh']}}</el-button>
        <el-button :disabled="isLoading" @click="this.$router.go(-1);">{{lang['html.el-button.return']}}</el-button>
        <el-button :disabled="isLoading" @click="openFilter">{{lang['html.el-button.filter']}}</el-button>

        <el-popconfirm :title="lang['html.el-popconfirm.title']" @confirm="moveUserToSelf">
            <template #reference>
                <el-button :disabled="isLoading">{{lang['html.el-button.moveUser']}} [{{tableSelectArr.length}}]</el-button>
            </template>
        </el-popconfirm>

        <el-tag style="margin-left: 10px">{{lang['html.el-tag.agent']}}:{{agent}}</el-tag>
    </div>

    <el-table :data="tableData"
              style="width: 300%"
              stripe
              border

              v-loading=isLoading
              element-loading-text="Loading..."
              element-loading-background="rgba(0, 0, 0, 0.8)"

              @selection-change="handleSelectionChange"
    >
        <el-table-column type="selection" width="50"/>

        <el-table-column :label="lang['html.el-table-column.label.id']" width="90" highlight-current-row>
            <template #default="scope">
                {{ scope.row.id }}
            </template>
        </el-table-column>


        <el-table-column :label="lang['html.el-table-column.label.username']" highlight-current-row>
            <template #default="scope">
                {{ scope.row.username }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.nickname']" highlight-current-row>
            <template #default="scope">
                {{ scope.row.nickname }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.name']"  highlight-current-row>
            <template #default="scope">
                {{ scope.row.name }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.money']" width="90"  highlight-current-row>
            <template #default="scope">
                {{ version == 1 ?  scope.row.money_int :  scope.row.money_time}}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.login_time']"  highlight-current-row>
            <template #default="scope">
                {{ scope.row.login_time }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.reg_time']"  highlight-current-row>
            <template #default="scope">
                {{ scope.row.reg_time }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.status']" width="60"  highlight-current-row>
            <template #default="scope">
                {{ scope.row.status ===1 ? '√' : '×' }}
            </template>
        </el-table-column>
    </el-table>

    <div style="text-align: left;display: inline">
        <el-pagination background
                       layout="total,prev, pager, next,sizes,"
                       :total=pages.total
                       style="margin-top: 10px"
                       :disabled="isLoading"


                       v-model:currentPage="pages.page"
                       @current-change="changePage"

                       v-model:page-size="pages.size"
                       :page-sizes="[20,50,100,500,1000]"
                       @size-change="handleSizeChange"
        />
    </div>

    <!--    筛选器-->
    <userListFilter ref="userListFilterRef"
                    :tableData = this.tableDataAll
                    @watch-filter=watchFilterData
    />
</template>
<script>
import paging from "../../utils/paging";
import userListFilter from '../User/userList/userList-filter';
export default {
    name: "subordinateUsers",
    components:{
        userListFilter,
    },
    data(){
        return {
            //当前操作的下级代理商账号
            agent:'',
            //分页参数
            pages:{
                size:20,
                page:1,
                total:-1,
            },
            isLoading:true,
            lang:this.$lang.getLang('agent','subordinateUsers'),
            tableData:[],
            tableDataAll:[],
            tableSelectArr:[],
            version:this.$cookie.getCookie('version'),
        }
    },
    methods:{
        //打开筛选器
        openFilter(){
            this.$refs.userListFilterRef.setShow(true);
        },
        //[筛选器]接收筛选后的数据
        watchFilterData(obj){
            this.isLoading = true;
            this.$refs.userListFilterRef.setShow(false);
            let userData = obj;
            this.$notify({message:this.lang['watchFilterData.success'] + userData.length,type:'success'});
            this.pages.total = userData.length;
            paging.init(userData,this.pages.size);
            this.changePage(1);
            this.isLoading = false;
        },
        //回迁用户[将用户迁移到自己]
        moveUserToSelf(){
            let max = 20;
            if (this.tableSelectArr <= 0){
                this.$alert(this.lang['moveUserToSelf.error.userList']);
                return;
            }
            if (this.tableSelectArr.length > max){
                this.$alert(this.lang['moveUserToSelf.error.max']);
                return;
            }
            this.$notify({message:this.lang['moveUserToSelf.wait'],type:'info'});
            this.isLoading = true;
            let arr = [];
            for (let tableSelectArrKey in this.tableSelectArr) {
                let temp = this.tableSelectArr[tableSelectArrKey];
                arr.push(temp.username);
            }
            this.$api.post('User.UserMove/moveUserByLowAgent',{userList:arr.toString(),agent:this.agent})
                .then(res=>{
                    let data = this.$helper.checkRes(res);
                    if (!data){
                        let code = this.$helper.getResErrorCode(res);
                        this.$helper.alertErrMsg(this.lang,'moveUserToSelf.res.code.',code);
                        return;
                    }
                    this.$alert(this.lang['moveUserToSelf.res.code.100'],null,{type:'success'});
                }).catch(err=>this.$helper.axiosCatch(err)).finally(()=>this.isLoading=false);
        },
        //获取该代理商旗下的用户
        getAgentUserList(){
            this.isLoading = true;
            this.$notify(this.lang['getAgentUserList.wait']);
            this.$api.post('Agent.AgentData/getAgentUserList',{agent:this.agent}).then(res=>{
                let data = this.$helper.checkRes(res);
                if (!data){
                    let code = this.$helper.getResErrorCode(res);
                    console.log('dd',code,'getAgentUserList.res.code.'+code)
                    this.$helper.alertErrMsg(this.lang,'getAgentUserList.res.code.',code);
                    return;
                }
                let userData = data.data;
                if(!userData){
                    this.$alert(this.lang['getAgentUserList.error.userData']);
                    return;
                }
                this.tableDataAll = userData;
                this.pages.total = userData.length;
                paging.init(userData,this.pages.size);
                this.changePage(1);
            })
                .catch(err=>this.$helper.axiosCatch(err))
                .finally(()=>{this.isLoading=false});
        },
        handleSelectionChange(selectArr){
            this.tableSelectArr = selectArr;
        },
        changePage(page){
            this.pages.page = page;
            paging.setPage(page);
            this.tableData = paging.getPageData();
        },
        handleSizeChange(size){
            paging.setSize(size);
            this.changePage(1);
        },

    },
    mounted() {
        this.agent = this.$route.params.agent;
        this.getAgentUserList();
    }
}
</script>

<style scoped>
.tools{
    margin-bottom: 10px;
}
</style>